@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,500;0,600;0,700;1,100;1,400;1,500;1,600&display=swap');
*/
/*.gradiant-bg {
   @apply text-white transition duration-100 ease-in-out transform bg-gradient-to-tr from-[#70E000] to-mantis ;
}
*/
@layer utilities {
   @variants responsive {
      .no-scrollbar::-webkit-scrollbar {
         display: none;
      }

      .no-scrollbar {
         -ms-overflow-style: none;
         scrollbar-width: none;
      }
   }
}

body {
   padding: 0;
   margin: 0;
   font-family: "Figtree", sans-serif;
}

.poiret-one {
   font-family: "Poiret One", serif;
}
.pt-sans {
   font-family: "PT Sans", serif !important;
}

.line-clamp {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   max-width: 500px;
}

.container-slider {
   position: relative;
   overflow: hidden;
}

.slide {
   width: 100%;
   height: 100%;
   position: absolute;
   opacity: 0;
   transition: opacity ease-in-out 0.4s;
}
.slide img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.active-anim {
   opacity: 1;
}

/*.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}


.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}*/
.item-detail {
   width: 420px;
   height: 200px;
}

.location-container {
   border-bottom: honeydew !important;

   border-left: honeydew !important;
   border-right: honeydew !important;
   border-top: white !important;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
   border-radius: 0 0 2px 2px;
   z-index: 9999;
   margin-top: 5px;
}

.location-container-acti-listing {
   left: -50px;
   min-width: 200px;
   font-size: 14px;
   margin-top: 12px;
}

@media screen and (min-width: 800px) {
   .location-container-acti-listing {
      min-width: 296px;
      margin-top: 20px;
   }
}

/******typography******/

.text_display_large {
   font-weight: 700;
   font-size: 40px;
   line-height: 60px;
}

.text_display_medium {
   font-weight: 700;
   font-size: 32px;
   line-height: 40px;
}

.title_large {
   font-size: 16px;
   font-weight: 600;
   line-height: 22px;
   letter-spacing: 0.01em;
}

/*****************************/

.border_container {
   @apply rounded-lg border-border_color border-opacity-20;
}

.border_radius {
   @apply rounded-lg border-opacity-20;
}
.gradiant-bg {
   @apply text-white transition duration-100 ease-in-out transform bg-gradient-to-tr from-[#70E000] to-primary;
}

.card_shadow {
   box-shadow: 0px 4px 6px 0px #0000000d;

   box-shadow: 0px 10px 15px 0px #0000001a;
}

/************************** scroll bar edit ***************************/

#journal-scroll::-webkit-scrollbar {
   width: 8px;
   border-radius: 50px;
   cursor: pointer;
   /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
#journal-scroll::-webkit-scrollbar-track {
   background-color: transparent;

   cursor: pointer;
   /*background: red;*/
}
#journal-scroll::-webkit-scrollbar-thumb {
   cursor: pointer;

   border-radius: 50px;
   background-color: #758a80;
   /*outline: 1px solid slategrey;*/
}

/************************** scroll bar edit ***************************/

.static-scroll::-webkit-scrollbar {
   width: 0;
   height: 10px;
   -webkit-appearance: none;
   border-radius: 50px;
   cursor: pointer;
   /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}

.static-scroll::-webkit-scrollbar-track {
   background-color: #f1f3f2;
   margin-right: 100px;
   margin-left: 100px;
   border-radius: 50px;
   cursor: pointer;
   /*background: red;*/
}

.static-scroll::-webkit-scrollbar-thumb {
   cursor: pointer;
   border-radius: 50px;
   width: 40px;
   background-color: #00b562;
   /*outline: 1px solid slategrey;*/
}

.corporate-offerings-text span {
   @apply text-primary  text-xl font-bold md:text-3xl;
}

.hover-trigger .hover-target {
   display: none;
}

.hover-trigger:hover .hover-target {
   display: block;
}

@keyframes zoom-out {
   0% {
      opacity: 0;
      transform: scale(1);
   }
   20% {
      opacity: 0.4;
      transform: scale(0.6);
   }
   40% {
      opacity: 0.8;
      transform: scale(0.4);
   }
   100% {
      opacity: 1;
      transform: scale(0.2);
   }
}

.animation-zoom-in {
   animation: zoom-out 2s ease-in-out;
}

.new-button {
   border: 0;
   border-radius: 14px;
   margin: auto;
   text-align: center;
   letter-spacing: 0.25px;
   font-weight: 600;
   cursor: pointer;
   display: flex;
   gap: 16px;
   justify-content: center;
   border-bottom: 5px solid #00914e;
   align-items: center;
}

/* Used For Accordion for sitemaps */
input[type="checkbox"]:checked + label + .content {
   display: block;
}

/* Shimmer styles */
.shimmer {
   position: relative;
   overflow: hidden;
   background-color: rgb(229 231 235);
}

.shimmer::after {
   content: "";
   inset: 0;
   position: absolute;
   width: 200%;
   background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);
   animation: shimmer 2s infinite;
}

@keyframes shimmer {
   0% {
      transform: translateX(-100%);
   }
   100% {
      transform: translateX(100%);
   }
}

.animate-paused {
   animation-play-state: paused;
}

.bg-beginner {
   background-color: #baf1a1;
}

.bg-amateur {
   background-color: #43d8c9;
}

.bg-intermediate {
   background-color: #f3c623;
}

.bg-advance {
   background-color: #d8b9c3;
}

.bg-professional {
   background-color: #ff9d76;
}

.border-beginner {
   border-color: #baf1a1;
}

.border-amateur {
   border-color: #43d8c9;
}

.border-intermediate {
   border-color: #f3c623;
}

.border-advance {
   border-color: #d8b9c3;
}

.border-professional {
   border-color: #ff9d76;
}

/* to give spacing to scrollbar from edges give padding right to parent */
.custom-scrollbar::-webkit-scrollbar {
   width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
   background-color: rgba(59, 69, 64, 0.1); /* #3b4540 with 10% opacity */
   border-radius: 6px;
   margin: 0px 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
   cursor: pointer;
   background-color: #3b4540;
   border-radius: 6px;
   border: 3px solid rgba(59, 69, 64); /* Creates padding effect */
}
